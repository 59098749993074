.main {
  background-color: #fff;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.drawerHeader {
  background-color: black;
  height: 50px;
}