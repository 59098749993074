.sidebar {
    width: 145px;
    color: white;
    height: 100%;
    background-color: black;
}

.treeItem {
    margin-left: 10px;
    position: relative;
}

.treeLabelContent {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.treeLabel {
    cursor: pointer;
    padding: 5px;
    background-color: transparent;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
}

.treeLabel::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    width: calc(100% + 10px);
    height: 100%;
    background-color: transparent;
    z-index: -1;
}


.treeLabel:hover::before {
    background-color: #666;
}

.treeLabel.level1 {
    font-size: 16px;
}

.treeLabel.level2 {
    font-size: 12px;
}

.treeLabel:hover {
    background-color: #333;
}


.treeLabel.active {
    color: rgb(0, 138, 0);
}


.treeChildren {
    margin-left: 0;
    padding-left: 0;
}

.treeIcon {
    margin-right: 10px;
}

.treeDot {}

.treeItemWrapper {
    background-color: black;
    padding-left: 20px;
    position: relative;
    left: -20px;
    width: calc(100% + 20px);
}

.treeItemWrapper.open {
    background-color: #333;
}